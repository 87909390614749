@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

:root {
    --main-color: #969696;
    --title-color: #414141;
    --link-color: #0066FF;
    --background: #5251C4;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    max-width: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

html,
body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    color: var(--main-color);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    padding-top: 132px;
}

.title {
    color: var(--title-color);
    font-size: 38px;
    font-weight: 700;
}

.title-xs {
    color: var(--title-color);
    font-size: 28px;
    font-weight: 700;
}

header {
    padding: 15px 0px;
    color: var(--title-color);
    background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
}

section,
app-converter {
    line-height: 1.5;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

::selection {
    color: white;
    background: #ff7675;
}

::-webkit-selection {
    color: white;
    background: #ff7675;
}

::-moz-selection {
    color: white;
    background: #ff7675;
}

.container {
    width: calc(960px + 32vw);
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

p {
    margin: 0;
}

.hide {
    display: none !important;
}

.subtitle {
    font-size: 20px;
}

.title-wrapper {
    padding-top: calc(40px + 1.5vw);
    padding-bottom: calc(40px + 1.5vw);
}

.column-center {
    width: 800px;
}

.title-wrapper .container {
    width: 830px;
    max-width: 100%;
}

.btn {
    background: var(--background);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 55px;
    padding: 5px;
    border: 0;
    width: 259px;
    max-width: 100%;
    cursor: pointer;
    transition: all 0.3s;
}

.btn:hover {
    opacity: 0.9;
}

.btn span {
    display: inline-block;
    margin-right: 10px;
}

.btn.btn-transparent {
    background: transparent;
    color: var(--background);
    border: 2px solid  var(--background);
}

.p-2 {
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.m-auto {
    margin: auto;
}

.br-80 {
    border-radius: 80px;
}

@media (max-width: 1330px) {
    .title-wrapper .container {
        width: 610px;
    }
    .column-center {
        width: 580px;
    }
}

@media (max-width: 1110px) {
    .title-wrapper .container {
        width: 530px;
    }
    .column-center {
        width: 500px;
    }
}

@media (max-width: 1030px) {
    .column-ads {
        display: none;
    }

    .column-list {
        justify-content: center;
    }

    .row {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 800px) and (max-height: 600px),
        screen and (max-width: 800px) {
    .mobile-hidden {
        display: none !important;
    }

    .column-list {
        justify-content: center;
    }

    .column-center {
        width: 800px;
    }
}
